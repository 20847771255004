import moment from 'moment';
import { conditionOptions } from './configOptions';
import { formatMoney } from './currency';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const groupItemsByKey = (items, key) => {
  return items.reduce((acc, item) => {
    const keyValue = item?.[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(item);
    return acc;
  }, {});
};

export const groupSameSizeConditionListings = listings => {
  // First, group listings by 'condition' and 'size'
  const grouped = listings.reduce((acc, listing) => {
    const key = `${listing.condition.value}_${listing.size}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(listing);
    return acc;
  }, {});

  const selectedListings = Object.values(grouped).map(group => {
    // Sort listings by price, then by createdAt timestamp
    return group.sort((a, b) => {
      if (a.price.amount !== b.price.amount) {
        return a.price.amount - b.price.amount; // Ascending order by price
      }
      return a.createdAt - b.createdAt; // Ascending order by creation time if prices are equal
    })[0]; // Select the first item after sorting
  });

  return selectedListings;
};

export const getListingFieldOptions = (listingFields, key) => {
  return listingFields.find(l => l?.key === key)?.enumOptions || [];
};

export const structuredVariants = (variantListings, listingFields) => {
  if (!isArrayLength(variantListings)) return null;

  const sizeOptions = getListingFieldOptions(listingFields, 'size');

  const filteredVariants = [];

  variantListings.forEach(variantListing => {
    const price = variantListing.attributes.price;
    const createdAt = variantListing.attributes.createdAt;
    const publicData = variantListing.attributes.publicData;
    const originalGtPrice = publicData?.originalGtPrice;
    const transactionProcessAlias = publicData?.transactionProcessAlias;
    const author = variantListing.author;
    const images = variantListing?.images;
    const stock = variantListing?.currentStock?.attributes?.quantity;

    const existingVariant = filteredVariants.find(
      v =>
        v.size === publicData.size &&
        v.price.amount === price.amount &&
        v.condition.value === publicData.condition
    );

    if (existingVariant) {
      if (moment(createdAt).unix() < existingVariant.createdAt) {
        existingVariant.id = variantListing.id;
        existingVariant.createdAt = moment(createdAt).unix();
        existingVariant.price = price;
        existingVariant.stock = stock;
        existingVariant.images = images;
        existingVariant.originalGtPrice = originalGtPrice;
        existingVariant.transactionProcessAlias = transactionProcessAlias;
        existingVariant.condition = conditionOptions.find(o => o.value === publicData?.condition);
        existingVariant.author = author;
        existingVariant.attributes.publicData.transactionProcessAlias = transactionProcessAlias;
      }
    } else {
      filteredVariants.push({
        id: variantListing.id,
        createdAt: moment(createdAt).unix(),
        price,
        stock,
        author,
        images,
        attributes: {
          publicData: {
            transactionProcessAlias,
          },
        },
        size: sizeOptions.find(s => s.option === publicData?.size)?.label || publicData?.size,
        originalGtPrice,
        condition: conditionOptions.find(o => o.value === publicData?.condition),
      });
    }
  });

  return groupSameSizeConditionListings(filteredVariants);
};

export const mapVariantListingFields = (attributes, productData) => {
  return isArrayLength(attributes)
    ? attributes.map(attribute => {
        // Check if the current attribute key exists in productData
        if (productData.hasOwnProperty(attribute.key)) {
          const attributeData = productData[attribute.key];

          if (
            typeof attributeData === 'object' &&
            attributeData !== null &&
            'value' in attributeData
          ) {
            return {
              ...attribute,
              value: attributeData.label,
            };
          }

          return {
            ...attribute,
            value: attributeData,
          };
        }
        // If the key doesn't exist in productData, return the attribute as is
        return attribute;
      })
    : [];
};

export const getVariantPrices = (intl, publicData, currency) => {
  if (!publicData?.minProductPrice || !publicData?.maxProductPrice) return null;

  const { minProductPrice, maxProductPrice } = publicData;

  const minProductPriceMoney = new Money(minProductPrice, currency);
  const maxProductPriceMoney = new Money(maxProductPrice, currency);

  const minProductPriceFormatted = formatMoney(intl, minProductPriceMoney);
  const maxProductPriceFormatted = formatMoney(intl, maxProductPriceMoney);

  // show simplified price text if minProductPrice is equal to maxProductPrice
  if (minProductPrice === maxProductPrice) {
    return minProductPriceFormatted;
  } else {
    return `${minProductPriceFormatted} - ${maxProductPriceFormatted}`;
  }
};

/**
 * Checks if a transaction is a shipping transaction.
 *
 * @param {Object} tx - The transaction object.
 * @returns {number} - The offer price of the transaction
 */
export const getOfferPrice = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.offerParams?.offerPrice;
};

export const getOfferParams = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.offerParams;
};

export const allCounterCyclesCompleted = tx => {
  const transitions = tx?.attributes?.transitions;
  return (
    isArrayLength(transitions) &&
    transitions.filter(t => t.transition.includes('counter')).length === 4
  );
};

export const getDisplayName = currentUser => currentUser?.attributes?.profile?.displayName || '';

/**
 * Returns the URL of the image associated with a transaction's listing.
 * @param {object} tx - The transaction object.
 * @returns {string} The URL of the image, or a default image URL if input is invalid or image is missing.
 */
export const getTxImage = tx => {
  const currentListing = tx?.listing;
  const firstImage =
    currentListing && currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const sharetribeImage =
    firstImage &&
    firstImage.attributes &&
    firstImage.attributes.variants &&
    firstImage.attributes.variants['square-small2x'] &&
    firstImage.attributes.variants['square-small2x'].url;

  return sharetribeImage;
};

/**
 * Returns the title of the listing associated with a transaction.
 * @param {object} tx - The transaction object.
 * @returns {string|null} The listing title, or null if input is invalid or listing title is missing.
 */
export const getTxTitle = tx => {
  const currentListing = tx?.listing;
  const title = currentListing && currentListing.attributes && currentListing.attributes.title;
  return title;
};

export const isOwnOffer = (customer, currentUser) => {
  return customer?.id?.uuid === currentUser?.id?.uuid;
};

export const hasOfferExpired = createdAt => {
  const expirationDate = moment(createdAt).add(48, 'hours');
  const timeRemaining = moment.duration(expirationDate.diff(moment())).asHours();
  return timeRemaining <= 0;
};

export const calculatePercentage = (baseValue, percentage) => {
  if (typeof baseValue !== 'number' || typeof percentage !== 'number') {
    throw new TypeError('Both baseValue and percentage must be numbers');
  }

  return Number((baseValue * percentage) / 100).toFixed(2);
};

export const getUserDisplayName = user => {
  return (user?.id && user?.attributes?.profile?.displayName) || '';
};

export const getSelectedPaymentMethod = () => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    const selectedFromSession = window.sessionStorage.getItem('selectedPM');
    if (selectedFromSession) {
      return selectedFromSession;
    }
  }

  return 'card';
};

export const constructStripeElementsCheckoutParams = (
  sessionStorageValues,
  piParams = {},
  extraParams
) => {
  const listing = sessionStorageValues?.listing;
  const listingId = listing?.id;

  const {
    transactionProcessAlias,
    recipientCountryCode,
    variantListingId,
    billingDetails,
    isOffers,
    offerParams,
    transactionId,
  } = extraParams;

  return {
    listingId,
    protectedData: {
      unitType: 'item',
      deliveryMethod: 'shipping',
      piParams,
      variantListingId,
      paymentMethod: billingDetails?.paymentMethod,
      shippingDetails: {
        address: {
          line1: billingDetails?.recipientAddressLine1,
          line2: billingDetails?.recipientAddressLine2,
          postalCode: billingDetails?.recipientPostalCode,
          city: billingDetails?.recipientCity,
          state: billingDetails?.recipientState,
          country: billingDetails?.recipientCountry,
        },
        name: billingDetails?.recipientName,
        phoneNumber: billingDetails?.recipientPhoneNumber,
      },
    },
    extraParams: {
      transactionProcessAlias,
      recipientCountryCode,
      isOffers,
      offerParams,
      transactionId,
    },
  };
};

export const hasValidStripeIntentStatus = intent => {
  return intent?.redirect_status === 'succeeded';
};

export const isValidSepaKlarnaTransaction = tx => {
  return !!tx?.attributes?.protectedData?.piParams?.paymentIntentId;
};
